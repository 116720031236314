<template>
  <svg class="client-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 419.749 206.368" preserveAspectRatio="xMidYMid meet">
    <g data-name="Group 17">
      <path d="M87.983 60.114C78.77 48.368 68.406 43.3 53.435 43.3 21.42 43.3 0 66.562 0 101.111c0 32.475 21.19 56.2 50.44 56.2 15.431 0 27.869-5.988 37.542-18.2v10.825c0 20.038-11.286 31.554-30.863 31.554-13.359 0-23.493-4.606-32.936-14.741l-18.2 18.426c14.51 14.28 31.554 21.19 52.283 21.19 18.2 0 33.857-5.758 44.222-16.123 10.134-9.9 14.28-22.572 14.28-44.452V46.986h-28.79zm-28.33 9.213a29.66 29.66 0 0 1 20.038 8.292c5.3 5.528 7.831 13.359 7.831 24.875 0 8.061-2.073 14.741-5.528 18.886a29.285 29.285 0 0 1-22.111 9.9c-16.814 0-28.79-13.128-28.79-31.554 0-17.732 11.747-30.399 28.56-30.399zm70.073-22.341v106.638h29.251v-51.361c0-10.134.921-14.741 4.376-19.577 4.146-5.988 11.516-9.674 19.577-9.674 1.612 0 3.916.23 6.219.461V43.531a22.775 22.775 0 0 0-3.455-.23 30.54 30.54 0 0 0-17.274 5.067c-4.376 3.224-6.449 6.449-9.443 14.28V46.986zm98.96 0h-29.251v106.638h29.251zM214.176.23c-9.443 0-16.353 6.91-16.353 16.353 0 9.213 6.91 16.123 16.353 16.123A15.691 15.691 0 0 0 230.3 16.813C230.3 7.14 223.619.23 214.176.23zm57.175 46.755H242.1v106.639h29.251zM256.841.23c-9.443 0-16.353 6.91-16.353 16.353 0 9.213 6.91 16.123 16.353 16.123a15.691 15.691 0 0 0 16.122-15.892c0-9.674-6.679-16.584-16.122-16.584zm57.175 139.575c9.443 12.437 19.808 17.5 35.93 17.5 31.093 0 52.283-23.032 52.283-56.429 0-33.627-22.341-57.58-53.665-57.58-14.51 0-24.414 4.837-34.548 16.583V0h-29.251v153.624h29.251zm28.79-71.86c16.123 0 28.33 14.28 28.33 33.166 0 19.117-11.977 32.475-29.02 32.475-16.814 0-28.56-13.359-28.56-32.475-.001-18.426 12.897-33.166 29.25-33.166z" data-name="Path 1" />
      <path d="M409.661 46.006a10.276 10.276 0 0 1 4.978 1.3 9.372 9.372 0 0 1 3.747 3.714 10.2 10.2 0 0 1 1.363 5.071 10.147 10.147 0 0 1-1.336 5.029 9.51 9.51 0 0 1-3.727 3.727 10.147 10.147 0 0 1-5.025 1.336 10.147 10.147 0 0 1-5.025-1.337 9.51 9.51 0 0 1-3.732-3.726 10.147 10.147 0 0 1-1.332-5.025 10.2 10.2 0 0 1 1.363-5.075 9.372 9.372 0 0 1 3.747-3.721 10.276 10.276 0 0 1 4.979-1.293zm.013 1.976a8.229 8.229 0 0 0-3.991 1.038 7.554 7.554 0 0 0-3.009 2.99 8.192 8.192 0 0 0-1.1 4.083 8.177 8.177 0 0 0 1.073 4.045 7.668 7.668 0 0 0 3 3 8.114 8.114 0 0 0 4.027 1.082 8.1 8.1 0 0 0 4.037-1.08 7.726 7.726 0 0 0 3-3 8.138 8.138 0 0 0 1.08-4.043 8.192 8.192 0 0 0-1.1-4.083 7.542 7.542 0 0 0-3.016-2.99 8.267 8.267 0 0 0-4.001-1.042zm-4.57 13.512V50.787h2.173q3.056 0 3.424.026a4.219 4.219 0 0 1 1.679.428 2.684 2.684 0 0 1 1 1.027 3 3 0 0 1 .4 1.534 2.824 2.824 0 0 1-.757 1.982 3.45 3.45 0 0 1-2.127 1.021 2.678 2.678 0 0 1 .751.4 5.357 5.357 0 0 1 .724.823q.119.158.79 1.3l1.264 2.16h-2.683l-.9-1.739a7.246 7.246 0 0 0-1.5-2.206 2.4 2.4 0 0 0-1.5-.454h-.565v4.4zm2.173-6.084h.882a10.731 10.731 0 0 0 2.338-.145 1.378 1.378 0 0 0 .718-.507 1.364 1.364 0 0 0 .263-.823 1.31 1.31 0 0 0-.257-.8 1.407 1.407 0 0 0-.731-.5 10.393 10.393 0 0 0-2.331-.151h-.882z" data-name="Path 10" />
    </g>
  </svg>
</template>

<script>
export default {}
</script>

<style lang="scss">
.client-logo {
  path {
    fill: $black;
    transition: fill .5s ease;
  }
}
</style>
