<template>
  <a
    v-if="link.external && link.path"
    rel="external noreferrer"
    target="_blank"
    :href="link.path"
  >
    <slot>
      <span>
        {{ link.text }}
        <arrow v-if="arrow" />
      </span>
    </slot>
  </a>
  <router-link
    v-else-if="link.path"
    :to="link.path"
    :title="link.text || ''"
  >
    <slot>
      <span>
        <arrow
          class="backarrow"
          v-if="backarrow"
        />{{ link.text }}<arrow v-if="arrow" />
      </span>
    </slot>
  </router-link>
</template>

<script>
import Arrow from '@/components/elements/arrow.vue'

export default {
  props: [ 'link', 'arrow', 'backarrow' ],
  components: {
    'arrow': Arrow
  }
}
</script>
