<template>
  <div
    class="burger-icon"
    @click="$app.toggleMobileMenu()"
    :class="{'active': app.mobileMenu}"
  >
    <div class="lines">
      <span></span>
      <span></span>
    </div>
    <div class="text">
      {{ app.mobileMenu ? 'close' : 'menu' }}
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    app(){
      return this.$app.state;
    }
  },
}
</script>

<style lang="scss">
.burger-icon {
  position: absolute;
  top: 20px;
  right: 0;
  width: 180px;
  height: 100px;
  color: $black;
  cursor: pointer;
  transition: all .5s;
  @media only screen and (max-width: $m) {
    top: 10px;
  }
  @media only screen and (max-width: $s) {
    top: 0px;
    width: 160px;
  }
  @media only screen and (max-width: $xxs) {
    width: 150px;
  }
  .scrolled & {
    height: 79px;
  }
  .lines {
    position: absolute;
    width: 32px;
    height: 12px;
    top: 42px;
    left: 25px;
    transition: right .5s ease;
  }
  .text {
    position: absolute;
    text-transform: uppercase;
    font-weight: 600;
    left: 75px;
    top: 40px;
    letter-spacing: 3px;
    font-size: 18px;
  }
  span {
    display: block;
    height: 2px;
    border-radius: 4px;
    background-color: $black;
    position: absolute;
    left: 0;
    width: 100%;
    transition: all .3s $ease-out-quart;
    &:nth-child(1){
      top: 0;
    }
    &:nth-child(2){
      bottom: 0;
    }
  }
  &.active {
    span {
      &:nth-child(1){
        top: 5px;
      }
      &:nth-child(2){
        bottom: 5px;
      }
    }
  }
}
#app {
  &.app-mobile-menu {
    .burger-icon {
      .text {
        color: $black;
      }
      .lines {
        span {
          background-color: $black;
        }
      }
    }
  }
}
</style>
