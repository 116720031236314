<template>
  <header
    id="app-header"
    @mouseleave="submenu.active = false"
  >
    <div class="grid v-spacing-m">
      <div class="row v-center">
        <div class="col col-logo">
          <div class="inner">
            <!-- logo -->
            <router-link
              class="logo-link"
              to="/"
            >
              <client-logo />
            </router-link>
          </div>
        </div>
        <div
          class="col col-main-menu"
          v-if="$window.isAbove('l')"
        >
          <!-- main menu -->
          <nav>
            <ul class="menu inline-menu">
              <li
                v-for="(item, i) in api.menus.main"
                :key="'item-'+i"
                @mouseenter="menuhover(item, $event)"
              >
                <link-helper
                  :link="item.link"
                  :class="item.class"
                />
              </li>
            </ul>
          </nav>
        </div>
        <div class="col col-secondary-menu">
          <!-- secondary menu -->
          <nav v-if="$window.isAbove('l')">
            <ul class="menu inline-menu">
              <li
                v-for="(item, i) in api.menus.secondary"
                :key="'item-'+i"
                @mouseenter="menuhover(item, $event)"
              >
                <link-helper
                  :link="item.link"
                  :class="item.class"
                />
              </li>
            </ul>
          </nav>

          <!-- burger menu -->
          <burger-icon
            v-else
          />
        </div>
      </div>
    </div>

    <!-- desktop dropdown menu -->
    <transition name="menu-dropdown">
      <nav
        v-if="submenu.active"
        ref="menuDropdown"
        class="menu-dropdown"
        :style="{left: submenu.offset}"
      >
        <div
          class="background"
          :style="{height: submenu.height}"
        ></div>
        <ul
          v-if="submenu.items"
          class="menu"
        >
          <li
            v-for="(item, i) in submenu.items"
            :key="'menu-item-'+i"
          >
            <link-helper
              :link="item.link"
              :class="item.class"
            />
          </li>
        </ul>
      </nav>
    </transition>
  </header>
</template>

<script>
import ClientLogo from '@/components/elements/client-logo.vue'
import BurgerIcon from '@/components/elements/burger-icon.vue'
import Link from '@/components/helpers/link.vue'

export default {
  components: {
    'client-logo': ClientLogo,
    'burger-icon': BurgerIcon,
    'link-helper': Link
  },
  data() {
    return {
      submenu: {
        active: false,
        items: false,
        offset: 0
      }
    }
  },
  computed: {
    api(){
      return this.$api.state;
    }
  },
  methods: {
    // hovering over top level menu item
    menuhover: function(item, e){
      var v = this;
      if(item.children.length){
        v.submenu = {
          active: true,
          items: item.children,
          height: false,
          offset: e.target.offsetLeft+'px'
        };
        v.$nextTick(function(){
          v.submenu.height = v.$refs.menuDropdown.offsetHeight+'px';
        });
      } else {
        v.submenu.active = false;
      }
    }
  }
}
</script>

<style lang="scss">
#app-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: all .3s $ease-out-quart, background-color .2s ease;
  .grid, .client-logo {
    transition: all .5s ease;
  }
  .col-logo {
    margin-bottom: 6px; // centers logo better
    .client-logo {
      width: 100px;
    }
  }
  .col-main-menu {
    margin-left: 60px;
    text-transform: lowercase;
    font-weight: 600;
    font-size: 22px;
    letter-spacing: 2px;
    @media only screen and (max-width: $xxl) {
      font-size: 20px;
    }
    @media only screen and (max-width: $xl) {
      letter-spacing: 2px;
      margin-left: 45px;
    }
  }
  .col-secondary-menu {
    flex-grow: 1;
    justify-content: flex-end;
    font-size: 20px;
  }
  .menu {
    &.inline-menu {
      a {
        transition: all .5s ease;
        position: relative;
        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: -15px;
          left: 0;
          height: 4px;
          width: 0;
          background-color: $black;
          transition: all .3s $ease-out-quart;
        }
        &:hover, &.router-link-active {
          &:after {
            width: 100%;
          }
        }
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
  .menu-dropdown {
    position: absolute;
    top: 80%;
    left: 0;
    padding: 10px 15px;
    min-width: 150px;
    transition: left .4s $ease-out-quart;
    .background {
      position: absolute;
      @include fill();
      background-color: $white;
      transition: height .3s $ease-out-quart;
      box-shadow: 0px 10px 10px $fade;
    }
    ul {
      position: relative;
      li {
        a {
          margin: 0;
          font-size: 18px;
        }
      }
    }
  }
  // inverted text
  .template-home &, .slug-connect &, .slug-grow &, .work-page & {
    .client-logo {
      path {
        fill: $white;
      }
    }
    .menu {
      &.inline-menu {
        a {
          color: $white;
          &:after {
            background-color: $white;
          }
        }
      }
    }
    .burger-icon {
      color: $white;
      span {
        background-color: $white;
      }
    }
  }
  // scroll down
  @media only screen and (min-width: $l+1) {
    .scrolled.not-transitioning & {
      box-shadow: 0px 0px 10px $fade;
      background-color: $white;
      .grid {
        margin-top: calc($v-spacing-s/1.5);
        margin-bottom: calc($v-spacing-s/1.5);
      }
      .client-logo {
        width: 60px;
        path {
          fill: $black;
        }
      }
      .menu {
        &.inline-menu {
          a {
            color: $black;
            &:after {
              height: 2px;
              background-color: $black;
              bottom: -10px;
            }
          }
        }
      }
      .burger-icon {
        color: $black;
        top: -10px;
        span {
          background-color: $black;
        }
      }
    }
  }
  @media only screen and (max-width: $l) {
    .client-logo {
      width: 100px;
    }
    .scrolled-down & {
      position: absolute;
    }
    .scrolled-past-header & {
      position: fixed;
      box-shadow: 0px 0px 10px $fade;
      background-color: $white;
      .grid {
        margin-top: calc($v-spacing-s/1.5);
        margin-bottom: calc($v-spacing-s/1.5);
      }
      .client-logo {
        width: 60px;
        path {
          fill: $black;
        }
      }
      .menu {
        &.inline-menu {
          a {
            color: $black;
            &:after {
              opacity: 0;
            }
          }
        }
      }
      .burger-icon {
        color: $black;
        top: -10px;
        span {
          background-color: $black;
        }
      }
    }
    .scrolled-down.scrolled-past-header & {
      transition: box-shadow .2s ease, background-color .2s ease;
      transform: translate(0, -101%);
    }
  }
  @media only screen and (max-width: $s) {
    .grid {
      margin-top: $v-spacing-s;
      margin-bottom: $v-spacing-s;
    }
  }
}
</style>
