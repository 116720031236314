export default {
  install(Vue) {

    /*
    * default waypoint css class
    **************************************************************/
    Vue.prototype.$waypointClassIn = function() {
      return {
        active: true,
        callback: function(e){
          if(e.going === 'in'){
            e.el.classList.add('waypoint');
          }
        },
        options: {
          rootMargin: '0% 0% -3% 0%'
        }
      };
    };

    /*
    * default waypoint css class
    **************************************************************/
    Vue.prototype.$waypointClassInOut = function() {
      return {
        active: true,
        callback: function(e){
          if(e.going === 'in'){
            e.el.classList.add('waypoint');
          } else {
            e.el.classList.remove('waypoint');
          }
        },
        options: {
          rootMargin: '0% 0% -3% 0%'
        }
      };
    };

    /*
    * default waypoint css class
    **************************************************************/
    Vue.prototype.$waypoint = function(method) {
      return {
        active: true,
        callback: method
      };
    };

  }
};
