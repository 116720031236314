// https://github.com/date-fns/date-fns
import { format, distanceInWordsToNow, isFuture, isPast, isSameDay, isSameMonth, isSameMinute } from 'date-fns'

export default {
  install(Vue) {

    /*
    * date formats
    **************************************************************/
    Vue.prototype.$year = function() {
      return format(new Date(), 'YYYY');
    };
    Vue.prototype.$date = function(timestamp) {
      if(!timestamp || !_.isNumber(timestamp)){
        return '-';
      }
      return format(new Date(timestamp * 1000), 'ddd Do MMM, YYYY');
    };
    Vue.prototype.$dateFormat = function(timestamp, f) {
      if(!timestamp || !_.isNumber(timestamp)){
        return '-';
      }
      return format(new Date(timestamp * 1000), f);
    };
    Vue.prototype.$dateRange = function(date_range) {
      if(!date_range){
        return '-';
      }
      _.each(date_range, (date) => {
        if(!date || !_.isNumber(date) || !date || !_.isNumber(date)){
          return '-';
        }
      });
      var start = new Date(date_range.start * 1000);
      var end = new Date(date_range.end * 1000);
      if(isSameMinute(start, end)){
        return format(start, 'Do MMM h:mma');
      }
      if(isSameDay(start, end)){
        return format(start, 'Do MMM h:mma - ')+format(end, 'h:mma');
      }
      if(isSameMonth(start, end)){
        return format(start, 'Do MMM h:mma - ')+format(end, 'Do h:mma');
      }
      return format(start, 'Do MMM h:mma - ha Do MMM');
    };
    Vue.prototype.$relativeTime = function(timestamp) {
      if(!timestamp || !_.isNumber(timestamp)){
        return '-';
      }
      var date = new Date(timestamp * 1000);
      var prefix = isFuture(date) ? 'In ' : '';
      var suffix = isPast(date) ? ' ago' : '';
      return prefix+distanceInWordsToNow(date)+suffix;
    };

    /*
    * truncate
    **************************************************************/
    Vue.prototype.$truncate = function(str, length) {
      if(!str){
        return '-';
      }
      return str.length > length ? str.slice(0, length) + '...' : str;
    };

    /*
    * youtube id from url
    **************************************************************/
    Vue.prototype.$youtubeIdFromURL = function(url) {
      url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
    };

    /*
    * paragraph block content links
    * https://dennisreimann.de/articles/delegating-html-links-to-vue-router.html
    **************************************************************/
    Vue.prototype.$paragraphLinks = function($event){
      // ensure we use the link, in case the click has been received by a subelement
      let { target } = $event
      while (target && target.tagName !== 'A') target = target.parentNode
      // handle only links that occur inside the component and do not reference external resources
      if (target && target.matches("a:not([href*='://']):not([href*='tel:']):not([href*='mailto:'])") && target.href) {
        // some sanity checks taken from vue-router:
        // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
        const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = $event
        // don't handle with control keys
        if (metaKey || altKey || ctrlKey || shiftKey) return
        // don't handle when preventDefault called
        if (defaultPrevented) return
        // don't handle right clicks
        if (button !== undefined && button !== 0) return
        // don't handle if `target="_blank"`
        if (target && target.getAttribute) {
          const linkTarget = target.getAttribute('target')
          if (/\b_blank\b/i.test(linkTarget)) return
        }
        // don't handle same page links/anchors
        const url = new URL(target.href)
        const to = url.pathname
        $event.preventDefault();
        if(window.location.pathname !== to){
          this.$router.push(to)
        }
      }
      // external links should always open in new tab
      else if (target && target.matches("a[href*='://']") && target.href) {
        const url = new URL(target.href)
        $event.preventDefault();
        window.open(url, '_blank');
      }
    };

  }
};
