<template>
  <div class="app-title">
    <div
      class="background"
      :style="{'background-color': background}"
    />
    <div class="grid h-padding">
      <div class="row space-between">
        <div class="col col-3-of-4 col-m-2-of-2 content v-spacing-m">
          <div class="overflow-hidden keep-linebreaks">
            <transition
              name="text-up-fade"
              mode="out-in"
            >
              <h2 class="h1" v-if="thePost && postType === 'articles'" :key="'articles'">Articles</h2>
              <h1 v-else-if="thePost" :key="thePost.id">{{ title }}</h1>
              <h1 v-else :key="'spacer'">&nbsp;</h1>
            </transition>
          </div>
          <transition name="fade">
            <p v-if="postType === 'articles'">
              {{ $date(date) }}
            </p>
          </transition>
        </div>
        <transition
          name="fade"
          mode="out-in"
        >
          <div
            class="col col-1-of-4 content v-spacing-m"
            v-if="postType === 'articles' && $window.isAbove('m')"
            :key="'back-to-articles'"
          >
            <link-helper
              :link="{
                path: '/articles',
                text: 'Back to Articles'
              }"
              class="button orange"
              :backarrow="true"
            />
          </div>
          <div
            class="col col-1-of-4 text-right content v-spacing-m"
            v-if="postType === 'work' && $window.isAbove('m')"
            :key="'back-to-articles'"
          >
            <link-helper
              :link="{
                path: '/work',
                text: 'All Work'
              }"
              class="button white"
              :backarrow="true"
            />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import Link from '@/components/helpers/link.vue'

export default {
  props: [ 'post' ],
  components: {
    'link-helper': Link
  },
  computed: {
    thePost(){
      return this.$app.state.thePost;
    },
    title(){
      return _.get(this.thePost, 'content.header.textarea.textarea.value') || this.thePost.title;
    },
    postType(){
      return _.get(this.post, 'post_type');
    },
    background(){
      return _.get(this.post || this.thePost, 'content.details.color.color.value');
    },
    date(){
      return _.get(this.post, 'content.details.publish_date.date_time.value');
    },
  }
}
</script>

<style lang="scss">
.app-title {
  min-height: 390px;
  @media only screen and (max-width: $xxl) {
    min-height: 342px;
  }
  @media only screen and (max-width: $xl) {
    min-height: 314px;
  }
  @media only screen and (max-width: $m) {
    min-height: 280px;
  }
  @media only screen and (max-width: $s) {
    min-height: 0;
    .keep-linebreaks {
      white-space: normal;
    }
  }
  &.floating-title {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    .background {
      background-color: $white;
      transition: all 1s;
      position: absolute;
      @include fill();
    }
  }
  &.hidden-title {
    opacity: 0;
    pointer-events: none;
  }
  .grid {
    position: relative;
  }
  .overflow-hidden {
    padding-bottom: 10px;
  }
  // colours
  .slug-connect & {
    .background {
      background-color: $orange;
    }
    h1, h2 {
      color: $white;
    }
  }
  .slug-grow & {
    .background {
      background-color: $blue;
    }
    h1, h2 {
      color: $white;
    }
  }
  .slug-inspire & {
    .background {
      background-color: $yellow;
    }
  }
  .slug-work & {
    @media only screen and (max-width: $s) {
      min-height: 260px;
    }
  }
  .slug-work &, .frontpage & {
    .background {
      background-color: $light-grey;
    }
  }
  .slug-workshops & {
    .background {
      color: $blue;
    }
  }
  .slug-contact &, .article-page & {
    h1, h2 {
      color: $orange;
    }
  }
  .work-page & {
    h1, h2 {
      color: $white;
    }
  }
}
</style>
