<template>
  <div id="mobile-menu">
    <nav class="x-center text-center">
      <ul class="menu main-menu">
        <li
          v-for="(item, i) in api.menus.main"
          :key="'item-'+i"
        >
          <link-helper
            :link="item.link"
            :class="item.class"
          />
        </li>
      </ul>
      <ul class="menu inline-menu secondary-menu">
        <li
          v-for="(item, i) in api.menus.secondary"
          :key="'item-'+i"
        >
          <link-helper
            :link="item.link"
            :class="item.class"
          />
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import Link from '@/components/helpers/link.vue'

export default {
  components: {
    'link-helper': Link
  },
  computed: {
    api(){
      return this.$api.state;
    }
  },
}
</script>

<style lang="scss">
#mobile-menu {
  position: fixed;
  background-color: $yellow;
  @include fill();
  @include h-center();
  .x-center {
    flex-direction: column;
  }
  .menu {
    li {
      a {
        color: $black;
        font-size: 26px;
        margin: 15px 0;
        &.router-link-active {
          opacity: 0.2;
        }
      }
    }
    &.main-menu {
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 5px;
      li {
        opacity: 0;
        animation: scale-in .3s forwards;
        @for $i from 1 through 6 {
          &:nth-child(#{$i}) {
            animation-delay: ($i * 0.05)+s;
          }
        }
      }
    }
    &.secondary-menu {
      margin-top: 80px;
      min-width: 400px;
      max-width: 400px;
      display: flex;
      justify-content: space-between;
      position: relative;
      &:before {
        content: '';
        display: block;
        position: absolute;
        height: 1px;
        background: $black;
        left: 0;
        top: 0;
        width: 100%;
        transform-origin: 0% 50%;
        transform: scale3d(0, 1, 1);
        animation: scale-in .3s .4s forwards;
      }
      li {
        opacity: 0;
        animation: scale-in .3s forwards;
        @for $i from 1 through 6 {
          &:nth-child(#{$i}) {
            animation-delay: (0.4 + $i * 0.1)+s;
          }
        }
      }
    }
    @media only screen and (max-width: $s) {
      li {
        a {
          font-size: 22px;
          margin: 12px 0;
        }
      }
      &.secondary-menu {
        min-width: 70vw;
        margin-top: 30px;
      }
    }
    @media only screen and (orientation: landscape) {
      li {
        a {
          font-size: 22px;
          margin: 5px 0;
        }
      }
      &.secondary-menu {
        margin-top: 20px;
      }
    }
    @media only screen and (max-width: $xs) {
      li {
        padding: 8px;
        a {
          font-size: 20px;
          margin: 8px 0;
        }
      }
    }
    @media only screen and (max-width: $xxs) {
      li {
        padding: 5px;
      }
    }
    @media only screen and (orientation: landscape) and (max-height: 460px) {
      li {
        a {
          font-size: 18px;
          margin: 0;
        }
      }
      &.main-menu {
        li {
          padding: 5px;
        }
      }
    }
  }
}
#app {
  &.app-mobile-menu {
    #app-header {
      box-shadow: none;
      background-color: transparent;
      svg {
        path {
          fill: $black;
        }
        .logo-b {
            fill: none;
        }
      }
      .burger-icon {
        span {
          background: $black;
        }
      }
    }
  }
}
</style>
