<template>
  <div
    id="app"
    :class="appClasses"
  >
    <!-- view -->
    <div id="app-content">
      <transition name="app-title">
        <app-title
          :post="post"
          class="floating-title"
          v-if="!frontpage && theSlug && !thePost.error && api.ready && !scrolledPastHeader"
        />
      </transition>
      <main id="app-view">
        <transition
          name="app-view"
          mode="out-in"
          @after-leave="viewHidden"
          @before-leave="transitioning = true"
          @after-enter="transitioning = false"
        >
          <router-view
            v-if="thePost"
            :key="thePost.id"
          />
        </transition>
      </main>

      <!-- footer -->
      <app-footer
        :class="{'hidden': !api.ready || transitioning}"
      />
    </div>

    <!-- loading spinner -->
    <transition name="app-loading">
      <loading-spinner
        id="app-loading"
        v-if="!api.ready || api.loading"
      />
    </transition>

    <!-- mobile menu -->
    <transition name="mobile-menu">
      <mobile-menu
        v-if="app.mobileMenu && $window.isBelow('l')"
      />
    </transition>

    <!-- header -->
    <transition name="fade">
      <app-header v-if="api.ready" />
    </transition>
  </div>
</template>

<script>
import Header from '@/components/app-header.vue'
import Footer from '@/components/app-footer.vue'
import Title from '@/components/app-title.vue'
import MobileMenu from '@/components/mobile-menu.vue'
import LoadingSpinner from '@/components/elements/loading-spinner.vue'

export default {
  name: 'App',
  components: {
    'app-header': Header,
    'app-footer': Footer,
    'app-title': Title,
    'mobile-menu': MobileMenu,
    'loading-spinner': LoadingSpinner
  },
  data() {
    return {
      post: false, // post during transitions
      postHidden: false, // post during transitions
      transitioning: true,
      bluePage: false,
      yellowPage: false,
      scrolledDown: false
    }
  },
  watch: {
    '$window.state.scroll': function(scroll, prev){
      this.scrolledDown = (scroll > 0) && (scroll > prev);
    },
    // watch for post first loaded
    'api.ready': function(ready, loaded){
      this.post = this.thePost;
    },
    // post changed and hidden
    'thePost': function(thePost){
      if(thePost && this.postHidden){
        this.postHidden = false;
        this.post = thePost;
      }
    },
    // post hidden and changed
    'postHidden': function(hidden){
      if(hidden && this.thePost){
        this.postHidden = false;
        this.post = this.thePost;
      }
    }
  },
  computed: {
    api(){
      return this.$api.state;
    },
    app(){
      return this.$app.state;
    },
    thePost(){
      return this.app.thePost;
    },
    theSlug(){
      return _.get(this.thePost, 'permalink.slug');
    },
    scrolled(){
      return this.$window.state.scroll > 0;
    },
    scrolledPastHeader(){
      return this.$window.state.scroll > 100;
    },
    frontpage(){
      return this.$route.path === '/';
    },
    appClasses(){
      var v = this;
      return v.app.classes.concat([
        {'app-mobile-menu': v.app.mobileMenu},
        {'api-ready': v.api.ready},
        {'api-loading': v.api.loading},
        {'not-transitioning': !v.transitioning},
        {'scrolled': v.scrolled},
        {'scrolled-down': v.scrolledDown && !v.app.mobileMenu},
        {'scrolled-past-header': v.scrolledPastHeader},
        {'yellow-page': _.indexOf(['inspire'], v.theSlug) !== -1},
        {'blue-page': _.indexOf(['grow', 'workshops', 'about'], v.theSlug) !== -1},
        {'article-page': v.theSlug === 'articles' || (v.thePost && v.thePost.post_type) === 'articles' },
        {'work-page': (v.thePost && v.thePost.post_type) === 'work'},
      ], [v.theSlug ? 'slug-'+v.theSlug : 'frontpage']);
    }
  },
  methods: {
    viewHidden(){
      var v = this;
      v.$nextTick(() => {
        window.scrollTo(0, 0);
        v.postHidden = true;
      });
    }
  }
}
</script>

<style lang="scss">
  // Vendors
  @import "~waffle-grid/src/waffle-grid";
  // Include global stylesheets
  @import '/assets/scss/reset';
  @import '/assets/scss/helpers';
  @import '/assets/scss/transitions';
  @import '/assets/scss/waypoints';
  @import '/assets/scss/keyframes';
  @import '/assets/scss/app';
  @import '/assets/scss/agile-carousel';
  @import '/assets/scss/forms';
  @import '/assets/scss/content';
  @import '/assets/scss/cards';
</style>
