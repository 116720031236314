<template>
  <footer id="app-footer">
    <div class="top overflow-hidden">
      <div class="grid">
        <div class="row">
          <div class="col col-7-of-12 col-l-2-of-2 col-s-1-of-3">
            <!-- footer menu -->
            <nav class="footer-menu">
              <ul
                v-for="(column, c) in menu_columns"
                :key="'column-'+c"
                class="menu"
              >
                <li
                  v-for="(item, i) in column"
                  :key="'item-'+i"
                  :class="item.class"
                >
                  <link-helper :link="item.link">
                    <template v-if="item.link.external">
                      <social-icon :type="item.class" /> {{ item.link.text }}
                    </template>
                  </link-helper>
                </li>
              </ul>
            </nav>
          </div>
          <div 
            v-if="award"
            class="award col col-3-of-12 col-s-1-of-3 text-right l-hide s-show"
          >
            <img
              :src="award"
              :alt="award_alt"
            />
          </div>
          <div class="col col-2-of-12 col-s-1-of-3 text-right l-hide s-show">
            <!-- logo -->
            <router-link to="/">
              <client-logo />
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom overflow-hidden">
      <div class="grid v-spacing-s">
        <div class="row v-center">
          <div class="col col-1-of-2 col-s-2-of-2">
            <!-- footer menu -->
            <nav class="bottom-menu">
              <ul class="menu inline-menu">
                <li
                  v-for="(item, i) in menus.smallprint"
                  :key="'item-'+i"
                >
                  <link-helper
                    :link="item.link"
                    :class="item.class"
                  />
                </li>
              </ul>
            </nav>
          </div>
          <div class="col col-1-of-2 col-s-2-of-2 text-right">
            <!-- copyright -->
            <div class="copyright">
              Copyright &copy; {{ $year() }} Griib Design Ltd
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import ClientLogo from '@/components/elements/client-logo.vue'
import Link from '@/components/helpers/link.vue'
import SocialIcon from '@/components/elements/social-icon.vue'

export default {
  components: {
    'client-logo': ClientLogo,
    'social-icon': SocialIcon,
    'link-helper': Link
  },
  computed: {
    api(){
      return this.$api.state;
    },
    menus(){
      return this.api.menus;
    },
    menu_columns(){
      return _.chunk(this.menus.footer, 3);
    },
    credit(){
      return _.get(this.api, 'options.credit');
    },
    award(){
      return _.get(this.api, 'options.footer_award.src');
    },
    award_alt(){
      return _.get(this.api, 'options.footer_award.alt');
    }
  },
}
</script>

<style lang="scss">
#app-footer {
  transition: opacity 1s ease;
  &.hidden {
    opacity: 0;
    transition: opacity .3s ease;
  }
  .top {
    background-color: $white;
    padding: calc($v-spacing-l/1.5) 0;
    @media only screen and (max-width: $s) {
      padding: $v-spacing-m 0;
    }
    a {
      display: inline-block;
    }
    .award {
      text-align: center;
      padding-top: 10px;
      img {
        max-width: 90px;
        @media only screen and (max-width: $s) {
          max-width: 60px;
        }
      }
    }
    .client-logo {
      padding-top: 10px;
      max-width: 130px;
      @media only screen and (max-width: $s) {
        max-width: 100px;
      }
    }
    .footer-menu {
      display: flex;
      justify-content: space-between;
      width: 100%;
      ul {
        @media only screen and (max-width: $s) {
          display: none;
          &:last-child {
            display: block;
          }
        }
        li {
          padding-left: 0;
          padding-right: 0;
          @media only screen and (max-width: $s) {
            display: none;
            &.linkedin, &.twitter {
              display: inline-block;
              font-size: 0px;
              margin-right: 13px;
              svg {
                max-width: 24px;
              }
            }
          }
        }
      }
      svg {
        max-width: 16px;
        fill: $black;
      }
    }
  }
  .bottom {
    background-color: $light-grey;
    font-size: 16px;
    a {
      text-decoration: underline;
    }
    @media only screen and (max-width: $s) {
      font-size: 14px;
      .bottom-menu {
        margin-bottom: $v-spacing-s;
      }
      .col, .text-right {
        text-align: center;
        justify-content: center;
      }
    }
  }
}
</style>
