var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-title"},[_c('div',{staticClass:"background",style:({'background-color': _vm.background})}),_c('div',{staticClass:"grid h-padding"},[_c('div',{staticClass:"row space-between"},[_c('div',{staticClass:"col col-3-of-4 col-m-2-of-2 content v-spacing-m"},[_c('div',{staticClass:"overflow-hidden keep-linebreaks"},[_c('transition',{attrs:{"name":"text-up-fade","mode":"out-in"}},[(_vm.thePost && _vm.postType === 'articles')?_c('h2',{key:'articles',staticClass:"h1"},[_vm._v("Articles")]):(_vm.thePost)?_c('h1',{key:_vm.thePost.id},[_vm._v(_vm._s(_vm.title))]):_c('h1',{key:'spacer'},[_vm._v(" ")])])],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.postType === 'articles')?_c('p',[_vm._v(" "+_vm._s(_vm.$date(_vm.date))+" ")]):_vm._e()])],1),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.postType === 'articles' && _vm.$window.isAbove('m'))?_c('div',{key:'back-to-articles',staticClass:"col col-1-of-4 content v-spacing-m"},[_c('link-helper',{staticClass:"button orange",attrs:{"link":{
              path: '/articles',
              text: 'Back to Articles'
            },"backarrow":true}})],1):_vm._e(),(_vm.postType === 'work' && _vm.$window.isAbove('m'))?_c('div',{key:'back-to-articles',staticClass:"col col-1-of-4 text-right content v-spacing-m"},[_c('link-helper',{staticClass:"button white",attrs:{"link":{
              path: '/work',
              text: 'All Work'
            },"backarrow":true}})],1):_vm._e()])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }